<style lang="less" scoped>
  .top {
    display: flex;
    align-items: center;
    background-color: #FFF;
    border-radius: 5px;
    padding: 10px;
    margin: 10px 10px 0 10px;
  }
  .content {
    margin: 5px;
    height: 0;
    flex: 1;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .calculate-page {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .wrap {
    width: 25%;
    padding: 5px;
    box-sizing: border-box;
  }
  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
  .years {
    flex: 1;
    width: 0;
    overflow: auto;
    white-space: nowrap;
  }
</style>

<style>
  .calculate-page .years .ivu-tag {
    cursor: pointer;
    border: none !important;
  }
</style>

<template>
  <div class="calculate-page">
    <div class="top flex-between">
      <div style="flex: 1;margin-right: 10px;" class="flex-center">
        <fm-title title-text="绩效管理"></fm-title>
        <div class="years scrollbar smallbar">
          <Tag size="large" @click.native="currentYear = year" :color="currentYear === year ? 'primary' : 'blue'" v-for="year in yearList" :key="year">{{year}} 年</Tag>
          <Tag size="large" color="volcano" v-if="!yearList.length">暂无数据</Tag>
        </div>
      </div>
      <div>
        <Button icon="md-refresh-circle" @click="loadData" :loading="loading.load">刷新</Button>
        <Poptip style="margin-left: 10px;" placement="left-start" title="新增月度绩效" v-if="isManage">
          <Button icon="md-add-circle" ref="addbtn" :loading="loading.add" type="primary">新增</Button>
          <div slot="content">
            <DatePicker v-model="formData.month" type="month" placeholder="请选择绩效月份" />
            <Button :loading="loading.add" @click="addSubmit" long type="primary" style="margin-top: 10px;display: block;">确定新增</Button>
          </div>
        </Poptip>
      </div>
    </div>
    <div class="content scrollbar">
      <TableEmpty style="display: flex;flex-direction: column;align-items: center;background: #FFF;justify-content: center;width: 100%;height: 100%;border-radius: 5px;" v-if="!monthList.length" />
      <div class="wrap" v-for="item in monthList" :key="item.month">
        <PfmItem :item="item" @reload="loadData" />
      </div>
    </div>
  </div>  
</template>

<script>
import { request } from '@/api'
import TableEmpty from '@/components/base/TableEmpty.vue'
import PfmItem from './items/index.vue'

import { getEmptyMonthItem } from './lib'

function getDefaultFormData () {
  return {
    month: new Date()
  }
}

export default {
  name: 'CalculatePage',
  components: { TableEmpty, PfmItem },
  data () {
    return {
      loading: {
        load: false,
        add: false
      },
      dataList: [],
      yearList: [],
      currentYear: null,
      formData: getDefaultFormData(),
      status: {
        form: false
      }
    }
  },
  computed: {
    monthList () {
      const year = this.currentYear
      if (!year) {
        return []
      }

      const monthList = this.dataList.filter(v => v.web.year === year)
      const month = monthList.map(v => v.web.month)
      const fill = []
      for (let i = 1; i <= 12; i++) {
        if (!month.includes(i)) {
          fill.push(getEmptyMonthItem(year + '-' + (i < 10 ? ('0' + i) : i) + '-01'))
        }
      }
      return [...monthList, ...fill].sort((a, b) => a.web.month - b.web.month)
    },
    isManage () {
      return this.$authFunsProxyNoRoute['performance.calculate.index-manage']
    }
  },
  methods: {
    async addSubmit () {
      if (!this.formData.month) {
        return this.$Message.error('请选择月份')
      }
      try {
        this.loading.add = true
        await request('/performance/pfm_month', 'post', {
          month: this.$datetime.format(this.formData.month, 'Y-M-D')
        })
        this.loading.add = false
        this.$Message.success('添加成功')
        this.loadData()
        this.$refs.addbtn.$el.click()
      } catch (e) {
        this.loading.add = false
        this.$Modal.error({ title: '添加失败', content: e.message })
        console.error(e)
      }
    },
    async loadData () {
      try {
        this.$Loading.start()
        this.loading.load = true
        const dataList = await this.$store.dispatch('loadPfmMonthList', true)
        dataList.forEach(v => {
          const split = v.month ? v.month.split('-') : [null, null]
          v.web = {
            year: Number(split[0]),
            month: Number(split[1])
          }
        })
        this.dataList = JSON.parse(JSON.stringify(dataList))
        this.loading.load = false
        this.yearList = Array.from(new Set(this.dataList.map(v => v.web.year).filter(v => v).sort((a, b) => b - a)))
        if (!this.currentYear || !this.yearList.includes(this.currentYear)) {
          this.currentYear = this.yearList[0]
        }
        this.$Loading.finish()
      } catch (e) {
        this.$Loading.error()
        console.error(e)
        this.loading.load = false
        this.$Modal.error({ title: '加载失败', content: e.message })
      }
    },
  },
  mounted () {
    this.loadData()
  }
}
</script>