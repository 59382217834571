<style lang="less" scoped>
  .pfm-item {
    padding: 20px;
    background-color: #FFF;
    transition: all .3s;
    border-radius: 5px;
    border: 1px solid #EEE;
    &.pointer {
      cursor: pointer;
    }
    .actions {
      opacity: 0;
      pointer-events: none;
      transition: all .3s;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-around;
    }
    .month {
      font-size: 40px;
      font-weight: bold;
      color: #000;
      display: inline-block;
    }
    .label {
      margin-left: 8px;
      color: #000;
    }
    .info {
      user-select: none;
      position: relative;
      transition: all .3s;
      top: 16px;
      .carx {
        overflow: hidden;
        display: flex;
        align-items: center;
        .iconfont {
          margin-right: 5px;
          font-size: 15px;
        }
        .value {
          font-size: 15px;
        }
        .unit {
          margin-left: 5px;
          font-size: 12px;
          color: #999;
        }
      }
    }
    &:hover {
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, .2);
      .info {
        top: 0;
      }
      .actions {
        opacity: 1;
        pointer-events: all;
      }
    }
  }
  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .flex-center {
    display: flex;
    align-items: center;
  }
</style>

<style>
  .pfm-item .ivu-tag {
    border: none !important;
  }
</style>

<template>
  <div class="pfm-item" v-loading="loading" :class="{pointer: actions[item.status] && actions[item.status].length}" @click="itemClick">
    <div class="info">
      <div class="flex-between">
        <div>
          <Tooltip :content="item.month" placement="right">
            <span class="month">{{item.web.month}}</span>
            <span class="label">月</span>
          </Tooltip>
        </div>
        <Tag size="large" v-if="item.status === 'end'" color="geekblue">已发布</Tag>
        <Tag size="large" v-else-if="item.status === 'divide'" color="purple">分配中</Tag>
        <Tag size="large" v-else-if="item.status === 'compute'" color="volcano">计算中</Tag>
        <Tag size="large" v-else-if="item.status === 'plan'" color="orange">准备中</Tag>
        <Tag size="large" v-else-if="item.status === 'empty'" color="default">未开始</Tag>
      </div>
      <Row type="flex" align="middle" justify="space-between" :wrap="false">
        <i-col span="8">
          <Tooltip class="block" content="普通工作量总绩效" placement="top-start">
            <div class="carx">
              <i class="iconfont icon-repeatInfinite2"></i>
              <span class="value" v-if="roleTypes.includes('manage')">{{item.unitValueTotal !== null ? item.unitValueTotal : '-'}}</span>
              <span class="value" v-else>***</span>
            </div>
          </Tooltip>
        </i-col>
        <i-col span="8">
          <Tooltip class="block" content="总人数" placement="top-start">
            <div class="carx">
              <i class="iconfont icon-zongrenshu"></i>
              <span class="value" v-if="roleTypes.includes('manage')">{{item.workerTotal !== null ? item.workerTotal : '-'}}</span>
              <span class="value" v-else>***</span>
              <span class="unit">人</span>
            </div>
          </Tooltip>
        </i-col>
        <i-col span="8">
          <Tooltip class="block" content="平均绩效" placement="top-start">
            <div class="carx">
              <i class="iconfont icon-jixiao"></i>
              <span class="value" v-if="roleTypes.includes('manage')">{{item.avgPfm !== null ? item.avgPfm : '-'}}</span>
              <span class="value" v-else>***</span>
            </div>
          </Tooltip>
        </i-col>
      </Row>
    </div>
    <div class="actions" @click.stop>
      <template v-if="actions[item.status] && actions[item.status].length">
        <template v-for="action in actions[item.status]">
          <Poptip :key="action.key + '-poptip'" v-if="action.confirm === true" confirm @on-ok="onActionClick(action)" placement="bottom" :title="'确定' + action.label + '吗？'">
            <Button size="small" type="text">{{action.label}}</Button>
          </Poptip>
          <Button size="small" :key="action.key + '-button'" @click="onActionClick(action)" v-else type="text">{{action.label}}</Button>
        </template>
      </template>
      <Button @click.stop disabled v-else icon="md-wifi" shape="circle" type="text">暂无操作</Button>
    </div>
  </div>
</template>

<script>
import { request } from '@/api'

export default {
  props: {
    item: { type: Object, default: null }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    roleTypes () {
      const types = []
      if (this.$authFunsProxyNoRoute['performance.calculate.index-manage']) {
        types.push('manage')
      }
      if (this.$authFunsProxyNoRoute['performance.calculate.index-operation']) {
        types.push('operation')
      }
      if (this.$authFunsProxyNoRoute['performance.calculate.index-view']) {
        types.push('view')
      }
      if (this.$authFunsProxyNoRoute['performance.calculate.index-editor']) {
        types.push('editor')
      }
      return types
    },
    actions () {
      let actions = {
        empty: [],
        plan: [],
        compute: [],
        divide: [],
        end: []
      }

      let btns = [
        // 未开始
        { label: '开始月度绩效计算', confirm: true, roles: ['manage'], status: ['empty'], key: 'start', default: true },
        // 准备中
        { label: '开始计算', confirm: false, roles: ['manage', 'editor'], status: ['plan'], key: 'startCompute', default: true },
        { label: '删除', confirm: true, roles: ['manage'], status: ['plan'], key: 'del' },
        // 计算中
        { label: '回到准备中', confirm: true, roles: ['manage'], status: ['compute'], key: 'backPlan' },
        { label: '查看', confirm: false, roles: ['view'], status: ['compute'], key: 'view', default: true },
        { label: '开始分配', confirm: false, roles: ['manage'], status: ['compute'], key: 'startDivide', default: true },
        // 分配中
        { label: '回到计算环节', confirm: true, roles: ['manage'], status: ['divide'], key: 'backCompute' },
        { label: '分配', confirm: false, roles: ['operation'], status: ['divide'], key: 'view', default: true },
        { label: '发布', confirm: false, roles: ['manage'], status: ['divide'], key: 'end', default: true },
        // 已发布
        { label: '回到分配环节', confirm: true, roles: ['manage'], status: ['end'], key: 'backDivide' },
        { label: '查看', confirm: false, roles: ['manage', 'operation', 'view'], status: ['end'], key: 'view', default: true }
      ]

      const roleTypes = this.roleTypes
      Object.keys(actions).forEach(status => {
        actions[status] = btns.filter(btn => {
          return btn.status.includes(status) && btn.roles.some(v => roleTypes.includes(v))
        })
      })

      return actions
    }
  },
  methods: {
    async itemClick () {
      if (!this.actions[this.item.status] || !this.actions[this.item.status].length) {
        return
      }

      const deftAction = this.actions[this.item.status].find(v => v.default) || this.actions[this.item.status][0]

      let confirm = true
      if (deftAction.confirm) {
        confirm = await this.$dialog.confirm('确定' + deftAction.label + '吗？')
      }

      if (confirm) {
        this.onActionClick(deftAction)
      }
    },
    reload () {
      this.$emit('reload')
    },
    async monthAdd () {
      try {
        if (this.loading) {
          return
        }
        this.loading = true
        await request('/performance/pfm_month', 'post', {
          month: this.item.month
        })
        this.reload()
        this.$Message.success('添加成功')
        this.loading = false
      } catch (e) {
        this.loading = false
        this.$Message.error('添加失败')
      }
    },
    onActionClick (action) {
      switch (action.key) {
        case 'start':
          this.monthAdd()
          break
        case 'backPlan':
          this.statusSwitch('plan')
          break
        case 'backCompute':
          this.statusSwitch('compute')
          break
        case 'backDivide':
          this.statusSwitch('divide')
          break
        case 'startCompute':
        case 'startDivide':
        case 'view':
        case 'end':
          this.$router.push({
            name: 'performance.calculate.details.menu',
            query: {
              id: this.item.id
            }
          })
          break
        case 'del':
          this.itemDel()
          break
        default:
          this.$Message.error('暂不支持')
          break
      }
    },
    async statusSwitch (status) {
      try {
        await request('/performance/pfm_month/status/' + this.item.id, 'post', { status })
        this.$Message.success('操作成功')
        this.$emit('reload')
      } catch (error) {
        this.$Modal.error({ title: '操作失败', content: error.message })
        console.error(error)
      }
    },
    async itemDel () {
      try {
        await request('/performance/pfm_month/' + this.item.id, 'delete')
        this.$Message.success('已删除')
        this.$emit('reload')
      } catch (error) {
        this.$Modal.error({ title: '删除失败', content: error.message })
        console.error(error)
      }
    },
  }
}
</script>